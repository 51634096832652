import { useCachedAPI } from "../../hooks/useCachedAPI";
import "./HomePage.css";
import TileHomePage from "./TileHomePage";
import { HomeConfigResult } from "./types";

const HomePage = () => {
  const result = useCachedAPI<HomeConfigResult>("/homeConfig.php");

  if (!result.value && result.loading) {
    return null; // TODO posar un spiner xupiguay
  }
  if (!result.value) {
    return null; // TODO posar que no es poden carregar les dades. Ha passat algun error
  }

  const regates = result.value.home.filter((v) => v.type === "regata");
  const rankings = result.value.home.filter((v) => v.type === "group");

  return (
    <div className="content">
      <div className="max-w-screen-sm m-auto">
        <div className="grid-container-regates">
          {regates.map((item) => {
            return item.type === "regata" ? (
              <TileHomePage key={item.id} item={item} />
            ) : null;
          })}
          {rankings.length > 0 && <TileHomePage item={rankings[0]} />}
        </div>
        {rankings.length > 0 && (
          <>
            <div className="rankings">
              <div className="hz-line"></div>
              <h1>Rànkings anteriors</h1>
              <div className="hz-line"></div>
            </div>
            <div className="grid-container-groups">
              {rankings.slice(1).map((item) => {
                return item.type === "group" ? (
                  <TileHomePage key={item.id} item={item} />
                ) : null;
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
